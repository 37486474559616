export const links = [
    {
        source: "qualia",
        target: "metaqualia" 
    },
    {
        source: "protoqualia",
        target: "qualia" 
    },    
    {
        source: "protoqualia",
        target: "metaqualia" 
    },
    {
        source: "awareness",
        target: "qualia" 
    },
    {
        source: "valence",
        target: "qualia" 
    },
    // {
    //     source: "valence",
    //     target: "ethics" 
    // },
    // {
    //     source: "valence",
    //     target: "aesthetics" 
    // },
    // {
    //     source: "story",
    //     target: "aesthetics" 
    // },
    // {
    //     source: "story",
    //     target: "tools" 
    // },
    // {
    //     source: "ethics",
    //     target: "aesthetics" 
    // },
    // {
    //     source: "alignment",
    //     target: "ethics" 
    // },
    // {
    //     source: "animal_welfare",
    //     target: "alignment" 
    // },
    // {
    //     source: "animal_welfare",
    //     target: "ethics" 
    // },
    {
        source: "qualia_cookbook",
        target: "internal_empiricism"
    },
    {
        source: "qualia_cookbook",
        target: "alignment" 
    },
    // {
    //     source: "qualia_cookbook",
    //     target: "tools" 
    // },
    // {
    //     source: "cracking_qualia",
    //     target: "tools" 
    // },
    // {
    //     source: "meditation",
    //     target: "tools" 
    // },
    // {
    //     source: "internal_empiricism",
    //     target: "tools" 
    // },
    {
        source: "internal_empiricism",
        target: "awareness" 
    },
    {
        source: "qualia_cookbook",
        target: "qualia" 
    },
    {
        source: "awareness",
        target: "flow" 
    },
    {
        source: "awareness",
        target: "curation" 
    },
    {
        source: "qualia",
        target: "cracking_qualia" 
    },
    {
        source: "qualia_cookbook",
        target: "cracking_qualia" 
    },
    {
        source: "awareness",
        target: "meditation" 
    }
]